import React, {  Suspense, lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import routes from './routes' // Route list
import Spinner from '../shared/Spinner'


const ProtectedRoutes = () => (
  <Switch>
    <Suspense fallback={<Spinner />}>
      {routes.map(option => (
        <Route path={option.path} key={option.path} exact={option.exact} component={option.component}/>
      ))}
    </Suspense>
  </Switch>
)

export default ProtectedRoutes

import React, { Component, Suspense, lazy } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import ProtectedRoutes from './ProtectedRoute'
import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'
import Spinner from '../shared/Spinner'
import * as localstorage from '../services/LocalStorage'

const Login = lazy(() => import('../user-pages/Login'))
const Error404 = lazy(() => import('../error-pages/Error404'))
const Error500 = lazy(() => import('../error-pages/Error500'))

class AppRoutes extends Component {
  isAuthed = () => {
    if (
      localstorage.loadAccess() !== undefined &&
      localstorage.loadAccess() !== null
    ) {
      return true
    } else {
      return false
    }
  }
  render () {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <PublicRoute path='/login' isAuthenticated={this.isAuthed()}>
            <Login />
          </PublicRoute>
          <PrivateRoute path='/' isAuthenticated={this.isAuthed()}>
            <ProtectedRoutes />
          </PrivateRoute>
          <Route path='*'>
            <Error404 />
          </Route>
          <Route path='/error-pages/error-500' component={Error500} />
          <Redirect to='/pages/dashboard' />
        </Switch>
      </Suspense>
    )
  }
}

export default AppRoutes

import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Collapse } from 'react-bootstrap'
import { Dropdown } from 'react-bootstrap'

class Sidebar extends Component {
  state = {}

  toggleMenuState (menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false })
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true })
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({ [i]: false })
      })
      this.setState({ [menuState]: true })
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged()
    }
  }

  onRouteChanged () {
    document.querySelector('#sidebar').classList.remove('active')
    Object.keys(this.state).forEach(i => {
      this.setState({ [i]: false })
    })

    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/basic-ui', state: 'basicUiMenuOpen' },
      { path: '/form-elements', state: 'formElementsMenuOpen' },
      { path: '/configurations', state: 'configurationsMenuOpen' },
      { path: '/icons', state: 'iconsMenuOpen' },
      { path: '/charts', state: 'chartsMenuOpen' },
      { path: '/user-pages', state: 'userPagesMenuOpen' },
      { path: '/error-pages', state: 'errorPagesMenuOpen' }
    ]

    dropdownPaths.forEach(obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true })
      }
    })
  }
  render () {
    return (
      <nav className='sidebar sidebar-offcanvas' id='sidebar'>
        <div className='text-center sidebar-brand-wrapper d-flex align-items-center'>
          <a className='sidebar-brand brand-logo' href='index.html'>
            <img
              src={require('../../assets/images/logo/logopaypos1.png')}
              alt='logo1'
            />
          </a>
          <a className='sidebar-brand brand-logo-mini pt-3' href='index.html'>
            <img
              src={require('../../assets/images/logo/favicon.png')}
              alt='logo2'
            />
          </a>
        </div>
        <ul className='nav'>
          <br />
          <br />
          <br />
          <br />
          <li
            className={
              this.isPathActive('/pages/dashboard')
                ? 'nav-item active'
                : 'nav-item'
            }
          >
            <Link className='nav-link' to='/pages/dashboard'>
              <i className='mdi mdi-chart-areaspline menu-icon'></i>
              <span className='menu-title'>
                Dashboard
              </span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive('/pages/transaction')
                ? 'nav-item active'
                : 'nav-item'
            }
          >
            <Link className='nav-link' to='/pages/transaction'>
              <i className='mdi mdi-transfer menu-icon'></i>
              <span className='menu-title'>
                Transactions
              </span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive('/configurations')
                ? 'nav-item active'
                : 'nav-item'
            }
          >
            <div
              className={
                this.state.configurationsMenuOpen
                  ? 'nav-link menu-expanded'
                  : 'nav-link'
              }
              onClick={() => this.toggleMenuState('configurationsMenuOpen')}
              data-toggle='collapse'
            >
              <i className='mdi mdi-settings menu-icon'></i>
              <span className='menu-title'>
                Configurations
                </span>
                <i className='menu-arrow'></i>
             
            </div>
            <Collapse in={this.state.configurationsMenuOpen}>
              <ul className='nav flex-column sub-menu'>
                <li className='nav-item'>
                  <Link
                    className={
                      this.isPathActive('/configurations/users')
                        ? 'nav-link active'
                        : 'nav-link'
                    }
                    to='/configurations/users'
                  >
                    Gestion des utilisateurs
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className={
                      this.isPathActive('/configurations/kiosks')
                        ? 'nav-link active'
                        : 'nav-link'
                    }
                    to='/configurations/kiosks'
                  >
                    Gestion des bornes
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
        </ul>
      </nav>
    )
  }

  isPathActive (path) {
    return this.props.location.pathname.startsWith(path)
  }

  componentDidMount () {
    this.onRouteChanged()
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body')
    document.querySelectorAll('.sidebar .nav-item').forEach(el => {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open')
        }
      })
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open')
        }
      })
    })
  }
}

export default withRouter(Sidebar)

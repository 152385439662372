import { lazy } from 'react'
const routes = [
  {
    path: '/pages/dashboard',
    component: lazy(() => import('../pages/dashboard/Dashboard')),
    exact: true
  },
  {
    path: '/pages/transaction',
    component: lazy(() => import('../pages/transactions/Transaction')),
    exact: true
  },
  {
    path: '/configurations/users',
    component: lazy(() => import('../pages/users/listusers')),
    exact: true
  },
  {
    path: '/configurations/adduser',
    component: lazy(() => import('../pages/users/adduser')),
    exact: true
  },
  {
    path: '/configurations/kiosks',
    component: lazy(() => import('../pages/kiosks/listkioks')),
    exact: true
  },
  {
    path: '/configurations/addkiosk',
    component: lazy(() => import('../pages/kiosks/addkiosk')),
    exact: true
  },
  {
    path: '/pages/profile',
    component: lazy(() => import('../pages/profile/profileuser')),
    exact: true
  },
  {
    path: '/pages/passwordmanagement',
    component: lazy(() => import('../pages/profile/passwordmanagement')),
    exact: true
  }
]

export default routes
